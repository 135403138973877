/* stylelint-disable no-descending-specificity */
/* stylelint-disable no-duplicate-selectors */

.new-edit-grid {
  display: grid;
  margin: 0 auto;
  justify-self: center;
  height: 17rem;
  min-width: 15rem;
  width: 15rem;
  grid-template-rows: auto repeat(2, minmax(min-content, max-content));
  grid-template-areas:
    "imgWithButton"
    "title"
    "edit";
  margin-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;

  & > :nth-child(1) {
    grid-area: "imgWithButton";
  }

  & > :nth-child(2) {
    margin-top: 0.5rem;
    grid-area: "title";
    text-align: left;
  }

  & > :nth-child(3) {
    grid-area: "edit";
    text-align: left;
  }

  @media (--viewport-s) {
    height: 17rem;
    width: 15rem;
  }
}

.thumbnail-button-wrapper {
  border: 1px solid var(--selected-font-color);
  background-color: var(--selected-background-color);
  border-radius: 5px;
  display: grid;

  &:hover {
    cursor: pointer;
    background-color: var(--selected-action-color);
  }
}

.thumbnail-button-wrapper {
  border: 1px solid var(--selected-font-color);
  background-color: var(--selected-background-color);
  border-radius: 5px;
  display: grid;

  &:hover {
    cursor: pointer;
    background-color: var(--selected-action-color);
  }
}

.thumbnail-wrapper {
  width: 11rem;
  padding: 1rem;
  justify-self: center;
  align-self: center;
}

.thumbnail-in-modal-wrapper {
  border: 1px solid var(--selected-font-color);
  background-color: var(--selected-background-color);
  border-radius: 5px;
  display: grid;
}

.title {
  padding: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.edit-modal-button {
  width: 100%;
}
